import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_CHAT_MASS_MESSAGING, ADMIN_USER_SEGMENTS_CREATE } from 'Consts/routes';

import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import ViewHeader from 'Components/layout/panel/ViewHeader';
import UsersSegmentsForm from 'Components/admin/users/UsersSegmentsForm';
import { TAB_SEGMENTS } from 'Components/pages/admin/ChatMassMessaging/component';

export default class AdminUsersSegmentsCreate extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };
    static defaultProps = {};

    render() {
        const { location, history } = this.props;

        return (
            <StyledComponent
                className="page-user-segments-create"
                styles={require('./styles')}
            >
                <PageContent            
                    breadcrumbs={[{
                        to: withVariables(ADMIN_CHAT_MASS_MESSAGING.path),
                        label: 'Masowa wysyłka wiadomości',
                    }, {
                        to: withVariables(ADMIN_CHAT_MASS_MESSAGING.path, {}, { tab: TAB_SEGMENTS }),
                        label: 'Segmenty użytkowników',
                    }, {
                        to: withVariables(ADMIN_USER_SEGMENTS_CREATE.path),
                        label: 'Nowe segment użytkowników',
                    }]}
                >
                    <ViewHeader
                        title="Nowy segment użytkowników"
                    />
                    <UsersSegmentsForm
                        location={location}
                        history={history}
                    />
                </PageContent>
            </StyledComponent>
        );
    }
}